import Vue from 'vue'
import VueRouter from 'vue-router'

import Landing from '@/components/views/Landing.vue';
import Story from '@/components/views/Story.vue';
import About from '@/components/views/About.vue';
import Resources from '@/components/views/Resources.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: "/home",
    name: "Landing",
    component: Landing,
  },
  {
    path: "",
    name: "Home",
    component: Landing,
  }, 
  {
    path: "/map",
    name: "Map",
    component: () => import(/* webpackChunkName: "Map" */ '../components/views/InteractiveMap.vue')
  },
  {
    path: "/story",
    name: "Story",
    component: Story, 
  },  
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources
  },  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
