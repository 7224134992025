<template>
  <!-- Footer - appears depending on tab -->
  <div id="footer" style="background-color:#E9E2DA;">
    <v-container outer>
      <v-row justify="space-between" align="start" class="pt-8 pb-2 my-0">
        <v-col cols="12" 
          md="2" lg="1"
          offset-md="1" offset-lg="2" class="order-md-2 text-center text-md-left footer-links">
          <p class="mb-md-2 mb-6 font-weight-bold" v-for="(item, i) of sitemap" :key="i">
            <router-link class="body-3--gi font-weight-bold text-uppercase" :to="{ name: item}">
              {{ item }}
            </router-link>
          </p>        
        </v-col>

        <v-col cols="12" 
          md="2"
          offset-md="1" class="order-md-3 text-center text-md-left">
          <h3 class="body-3--gi mb-2 font-weight-bold hidden-sm-and-down">FOLLOW US</h3>
          <SocialIcons :inputColor="'#47385C'" :size="30"/>                 
        </v-col> 

        <v-col cols="12" md="6" class="order-md-1 text-center text-md-left">
          <a href="#" target="_blank">
            <img src="@/assets/logo/logo-color.png" alt="logo" class="img-logo" />
          </a>
          <p class="overline--gi text--dark">Copyright Global Initiative Against Transnational Organized Crime 2021. All Rights Reserved.</p>
        </v-col>       
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SocialIcons from '@/components/ui/SocialIcons.vue';

export default {
  name: 'Footer',
  components: {
    SocialIcons
  },
  data() {
    return {
      sitemap: ['Story', 'Map', 'About'],
    }
  }
};
</script>

<style lang="scss" scoped>
.body-3--gi {
  color: #47385C !important;
}

a {
  border: none !important;
  text-decoration: none !important;
}

.footer-links {
  p:last-child {
    margin-bottom: 0 !important;
  }
}

.img-logo {
  width: 250px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-top: 24px;
    width: 180px;
  }
}
.v-footer {
  height: auto;
}
</style>
