<template>
  <section>
    <div class="view-landing">
      <v-container class="view-landing__frontmatter" outer fill-height align-end>
        <v-row>
          <v-col cols="12" md="7">
            <h1 class="heading-1--gi mb-8">
              China's Belt and Road Initiative: A web of (illicit) trade routes
            </h1>

            <h2 class="body-2--gi mb-8 font-weight-bold text--gold text-uppercase">
              VIRGINIA COMOLLI
            </h2>

            <h2 class="body-2--gi text--light">
              A network of land and maritime infrastructure opening up multiple entry points for criminal exploitation.
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionLanding',
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.view-landing {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-image: url('~@/assets/img/story-hero-image.png');
  height: calc(100vh - 75px);
  padding-bottom: 100px;  

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: calc(100vh - 65px);
  }
  
  &:before {
    content: '';
    background: linear-gradient(0.51deg, rgba(71, 56, 92, 0.8) 0.46%, rgba(51, 40, 66, 0.8) 97.39%);
    background-blend-mode: overlay;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;     
  }

  .view-landing__frontmatter {
    position: relative;
  }
}
</style>
