<template>
  <!-- Nav desktop -->
  <v-tabs 
    fixed-tabs
    background-color="#47385C"
    color="#47385C"
    height="75"
    hide-slider
    active-class="active">
    <v-tab  
      :ripple="false"
      v-for="(tab, i) of tabs"
      :key="i" :to="{ name: tab.name }">
        <span v-if="tab.label" class="body-1--gi font-weight-bold">{{ tab.label }}</span>
        <img
          v-else
          :src="currentRoute === 'Home' || currentRoute === 'Landing' ? 
          require('@/assets/logo/logo-color.png') :
          require('@/assets/logo/tablet-logo.png')"
          alt="logo" 
          height="60px"/>         
        <!-- <img v-else src="@/assets/logo/tablet-logo.png" alt="logo" height="60px"/> -->
    </v-tab>
  </v-tabs>
</template>

<script>

export default {
  name: 'Navbar',
  data() {
    return {
      tabs: [
        {name: 'Landing', label: ''},
        {name: 'Story', label: 'Story'},
        {name: 'Map', label: 'Map'},        
        {name: 'About', label: 'About'},
      ],    
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-slide-group__prev, .v-slide-group__next {
  display: none !important;
}

.active {
  background-color: #E9E2DA;
  .body-1--gi {
    color: #47385C !important;
  }
}

.v-tabs {
  flex: 0 1 auto !important;
}
</style>
