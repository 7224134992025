<template>
  <section class="view-resources">    
    <div class="view-resources__landing">
      <div class="view-resources__overlay" />
      <v-container outer pt-12 pt-md-0 pb-md-12>         
        <v-row class="py-0 py-md-12">
          <v-col cols="12" md="2" class="py-0 hidden-sm-and-down">
            <router-link :to="{ name: 'Landing'}"><img src="@/assets/logo/logo-space.png" alt="logo" width="100%" /></router-link>
          </v-col>          
          <v-col cols="12" md="12" class="py-0 py-md-12">
            <h1 class="heading-1--gi">
              Resources       
            </h1>
          </v-col>
        </v-row>          
      </v-container>
    </div>

    <div class="view-resources__references">
      <v-container outer py-12 pt-md-0 class="view-resources__references-content">
        <v-row class="py-0 pb-md-12">
          <v-col cols="12">
            <ol class="pl-0 mb-10">
              <li class="body-2--gi mb-12 text--light" v-for="(ref, i) in references" :key="i">
                {{ ref.text }}<span v-if="ref.link">, </span><span v-else>.</span>  
                <a :href= ref.link target="_blank">{{ ref.link }}</a> 
              </li>
            </ol>                       
          </v-col>        
        </v-row>             
      </v-container>
    </div>
  </section>
</template>
<script>

export default {
  name: 'Resources',
  data() {
    return {
      references: [
        {text: "AIPA, Inter-Parliamentary Assembly, Alternative development towards a drug-free ASEAN community, Laos country report, 2019",
        link: "https://www.parliament.go.th/ewtadmin/ewt/aipa2019/download/article/AIPACODD/Annex%20L%20-%20Country%20Report%20of%20Laos%20PDR.pdf"},
        {text: "ASEAN-NARCO, ASEAN Drug Monitoring Report 2017, August 2018", 
        link: "https://asean.org/wp-content/uploads/2016/10/Doc6-ADM-Report-2016-as-of-15-November-2017-FINAL.pdf"},
        {text: "André Baptista, Mozambican police reveal increased ivory trafficking, Journal of African Elephants, 26 October 2019", 
        link: "https://africanelephantjournal.com/mozambican-police-reveal-increased-ivory-trafficking/"},
        {text: "Shawn Blore and Marcena Hunter, Dubai’s problematic gold trade, in: Matthew T. Page and Jodi Vittori (eds.), Dubai’s role in facilitating corruption and global financial illicit flow, Carnegie Endowment for International Peace, 2020", 
        link: "https://carnegieendowment.org/files/PageVittori_DubaiCorruption_final.pdf"},
        {text: "Brainwave Science, Vietnam drug trafficking ring bust: 6 arrested in Hanoi, 9 June 2020", 
        link: "https://brainwavescience.com/stories/vietnam-drug-trafficking-ring-bust-6-arrested-in-hanoi-icognative/"},
        {text: "Louis Charbonneau, Cameroon involved in Central Africa ‘“blood diamond”’ trade: UN experts, Reuters, 1 September 2015", 
        link: "https://www.reuters.com/article/us-centralafrica-sanctions-diamonds-idUSKCN0R14Q920150901"},
        {text: "Pierre-Arnaud Chouvy, Drug trafficking in and out of the Golden Triangle, in: Pierre-Arnaud Chouvy (ed.), An Atlas of Trafficking in Southeast Asia. The Illegal Trade in Arms, Drugs, People, Counterfeit Goods and Natural Resources in Mainland Southeast Asia, London: I.B. Tauris, 2013"},
        {text: "Central Committee for Drug Abuse Control and UNODC, Myanmar Opium Survey 2018: Cultivation, production and implications, 31 December 2018", 
        link: "https://www.unodc.org/documents/crop-monitoring/Myanmar/Myanmar_Opium_Survey_2018-web.pdf"},
        {text: "Mohammed Daghar, East African human trafficking rings expand their operations, Institute for Security Studies, 30 July 2020", 
        link: "https://issafrica.org/iss-today/east-african-human-trafficking-rings-expand-their-operations"},
        {text: "Daily News,  Zanzibar plans to use its own funds on huge projects, 14 June 2019", 
        link: "https://allafrica.com/stories/201906140710.html"},
        {text: "Deanna Davy, Migrant smuggling in Asia: Current trends and related challenges, UNODC, July 2018", 
        link: "https://www.unodc.org/documents/human-trafficking/Migrant-Smuggling/2018-2019/SOM_in_Asia_and_the_Pacific_II_July_2018.pdf"},
        {text: "Arya Dipa, Human trafficking ring busted in West Java, The Jakarta Post, 26 July 2018", 
        link: "https://www.thejakartapost.com/news/2018/07/26/human-trafficking-ring-busted-in-west-java.html"},
        {text: "ECPAT UK, Precarious Journeys Mapping vulnerabilities of victims of trafficking from Vietnam to Europe, 2019", 
        link: "https://www.ecpat.org.uk/precarious-journeys"},
        {text: "ENACT, Illicit goods trafficking via port and airport facilities in Africa, June 2020", 
        link: "https://enact-africa.s3.amazonaws.com/site/uploads/2020-06-29-interpol-ports-threat-assessment-report.pdf"},
        {text: "Environmental Investigation Agency, Exposing the Hydra: The growing role of Vietnamese syndicates in ivory trafficking, 2018", 
        link: "https://eia-international.org/wp-content/uploads/EIA-report-exposing-the-hydra.pdf"},
        {text: "Maryanne Gicobi, Experts in fresh calls for joint efforts to end illegal trade in Somalia charcoal, The East African, 22 May 2018", 
        link: "https://www.theeastafrican.co.ke/tea/business/experts-in-fresh-calls-for-joint-efforts-to-end-illegal-trade-in-somalia-charcoal-1394330"},  
        {text: "GRID Arendal, The illegal charcoal trade controlled by Al Shabaab, 2014", 
        link: "https://www.grida.no/resources/7489"},              
        {text: "Simone Haysom, Peter Gastrow and Mark Shaw, The heroin coast: A political economy along the eastern African seaboard, ENACT, 2018", 
        link: "https://globalinitiative.net/wp-content/uploads/2018/07/2018-06-27-research-paper-heroin-coast-pdf.pdf"},
        {text: "Human Rights Watch, Give us a baby and we’ll let you go, trafficking of Kachin “brides” from Myanmar to China, 21 March 2019", 
        link: "https://reliefweb.int/report/myanmar/give-us-baby-and-we-ll-let-you-go-trafficking-kachin-brides-myanmar-china-enmy"},
        {text: "Marcena Hunter and Lynda Lawson, A rough cut trade: Africa’s coloured-gemstone flows to Asia, Global Initiative against Transnational Organized Crime, 2020", 
        link: "https://globalinitiative.net/analysis/africa-asia-gemstones-trade/"},
        {text: "Marcena Hunter, Tainted timber, Global Initiative against Transnational Organized Crime, 2018", 
        link: "https://globalinitiative.net/analysis/tainted_timber/ "},
        {text: "Francis E. Hutchinson and Kevin Zhang, Benefits and challenges of the Gemas-Johor Baru Railway Electrified Double-Tracking Project, The Star, 12 July 2020", 
        link: "https://www.thestar.com.my/opinion/letters/2020/07/12/benefits-and-challenges-of-the-gemas-johor-baru-railway-electrified-double-tracking-project"},
        {text: "Karlina Indraswari, Boyd T.C. Leupen, Minh D. T. Nguyen, and Maethinee Phassaraudomsak, Trading faces: A snapshot of the online ivory trade in Indonesia, Thailand and Vietnam in 2016 with an update in 2019, TRAFFIC, 2020", 
        link: "https://www.traffic.org/publications/reports/illicit-ivory-trade-in-indonesia-thailand-and-viet-nam/"},
        {text: "Institute for Policy, Advocacy, and Governance, Maritime security in Bay of Bengal: Potential challenges and opportunities, Institute for Policy, Advocacy, and Governance, 31 October 2019", 
        link: "https://ipag.org/maritime-security-in-bay-of-bengal-potential-challenges-and-opportunities/"},
        {text: "Intergovernmental Authority on Development, Ethiopia and Djibouti meet to map and analyze cross border security threats and criminal networks between the Dewele and Tog Wajaale Corridor, 29 August 2018", 
        link: "https://igad.int/divisions/peace-and-security/1928-ethiopia-and-djibouti-meet-to-map-and-analyze-cross-border-security-threats-and-criminal-networks-between-the-dewele-and-tog-wajaale-corridor. "},
        {text: "International Fund for Animal Welfare, Combating wildlife crime in Ethiopia, 11 July 2016", 
        link: "https://www.ifaw.org/international/journal/combating-wildlife-crime-in-ethiopia"},
        {text: "INTERPOL and ENACT, Overview of serious and organized crime in Central Africa, 2018", 
        link: "https://enactafrica.org/research/analytical-reports/interpol-overview-of-serious-and-organised-crime-in-central-africa-2018"},
        {text: "INTERPOL and ENACT, Overview of serious and organized crime in East Africa, 2018", 
        link: "https://enactafrica.org/research/analytical-reports/interpol-overview-of-serious-and-organised-crime-in-east-africa-2018"},
        {text: "Rafsan Jani, Gold smuggling: The strings are pulled from the Middle East, Dhaka Tribune, 7 November 2017", 
        link: "https://www.dhakatribune.com/bangladesh/crime/2017/11/06/gold-smuggling-strings-pulled-middle-east"},        
        {text: "Belinda Japhet, Is forced servitude driving up human trafficking In Tanzania?, Pesa Check, 6 February 2018", 
        link: "https://pesacheck.org/is-forced-domestic-servitude-tanzanias-biggest-human-trafficking-problem-8431fd3dab87"},
        {text: "Khmer Times, Exposing tricks of drug trafficking from Cambodia to Vietnam,  7 August 2020", 
        link: "https://www.khmertimeskh.com/50752737/exposing-trick-of-drug-trafficking-from-cambodia-to-vietnam/"},
        {text: "Kanitha Krishnasamy and Monica Zavagli, Southeast Asia: At the heart of wildlife trade, TRAFFIC, 2020", 
        link: "https://www.traffic.org/site/assets/files/12648/sea-traps-february-2020.pdf"},
        {text: "Kanitha Krishnasamy, Chris Sheperd and Oi  Ching Or, Observations of illegal wildlife trade in Boten, a Chinese border town within a Specific Economic Zone in northern Lao PDR, Global Ecology and Conservation, 14, April 2018", 
        link: "https://www.sciencedirect.com/science/article/pii/S2351989418300052. "},
        {text: "Leanne Little, Countering wildlife trafficking through Tanzania's seaports, TRAFFIC, 2019", 
        link: "https://www.traffic.org/publications/reports/countering-wildlife-trafficking-through-tanzanias-ports/"},
        {text: "Kahana Lukumbuzya and Cassian Sianga, Overview of the timber trade in East and Southern Africa: National perspectives and regional trade linkages, TRAFFIC, February 2017", 
        link: "https://www.trafficj.org/publication/17_Timber-trade-East-Southern-Africa.pdf"},
        {text: "Hai Thanh Luong, Mapping on transnational crime routes in the New Silk Road: A case study of the Greater Mekong Sub-region, The Chinese Journal of Global Governance, 6, 1, 2020"},
        {text: "Prem Mahadevan, Gilded Aspirations: Illicit gold flows to India, Global Initiative Against Transnational Organised Crime, August 2020", 
        link: "https://globalinitiative.net/wp-content/uploads/2020/08/Gilded-aspirations-Illicit-gold-flows-to-India-1.pdf"},        
        {text: "Malaysia Rail Line, ECRL Key Facts, accessed on 10 December 2020", 
        link: "http://www.mrl.com.my/en/ecrl-key-facts/"},
        {text: "Med Com, Police uncover Riau-Jakarta-Bandung drug ring, 14 March 2019", 
        link: "https://www.medcom.id/english/national/Rb13xv3K-police-uncover-riau-jakarta-bandung-drug-ring"},
        {text: "Khuon Narim, Massive illegal forest clearing found in Koh Kong province, Khmer Times, 7 May 2020", 
        link: "https://www.khmertimeskh.com/720606/massive-forest-clearing-found-in-koh-kong-province"},
        {text: "Sone Nkoke Christopher, Nya Fotseu Aimé, Ononino Alain Bernard, Guide to wildlife law enforcement, Cameroon: Competences, attributions, duties, and responsibilities of the different law enforcement agencies, TRAFFIC, January 2018", 
        link: "https://www.traffic.org/site/assets/files/8369/cameroon-law-enforcement-guide.pdf "},
        {text: "Duncan E. Omondi Gumba and Richard Chelin, China is proving key to reducing Africa’s wildlife trafficking, Institute for Security Studies, 19 August 2019", 
        link: "https://issafrica.org/iss-today/china-is-proving-key-to-reducing-africas-wildlife-trafficking"},
        {text: "Avirat Parekh, An alarming surge in illegal wildlife trade in Southeast Asia, The Jakarta Post, 19 August 2020", 
        link: "https://www.thejakartapost.com/academia/2020/08/19/an-alarming-surge-in-illegal-wildlife-trade-in-southeast-asia.html. "},
        {text: "Reconnecting Asia, National Road No 214 and Stung Treng-Mekong River Bridge, accessed on 6 January 2021", 
        link: "https://reconnectingasia.csis.org/database/projects/national-road-no-214-and-stung-treng-mekong-river-bridge/406332f0-4d66-4cab-a13d-1ac88e93f58b/"},
        {text: "Rafaela Rigoni, Sara Woods and Joost J. Breeksema, From opiates to methamphetamine: bBuilding new harm reduction responses in Jakarta, Indonesia, Harm Reduction Journal, 16, 1, 2019"},
        {text: "Khy Sovuthy, Biggest drug bust marks anti-drug day, Khmer Times, 27 June 2018", 
        link: "https://www.khmertimeskh.com/505352/biggest-drug-bust-marks-anti-drug-day/"},
        {text: "The Economist, How a Sino-Lao special economic zone hit the skids, 26 May 2011", 
        link: "https://www.economist.com/asia/2011/05/26/busted-flush"},
        {text: "The Jakarta Post, BNN finds 35 kg of crystal meth in truck carrying cabbages from Aceh, 26 May 2019", 
        link: "https://www.thejakartapost.com/news/2019/05/26/bnn-finds-35-kg-of-crystal-meth-in-truck-carrying-cabbages-from-aceh.html"},
        {text: "The Star, Indonesian police seize 200 kilos of meth from Myanmar, 30 July 2020", 
        link: "https://www.thestar.com.my/aseanplus/aseanplus-news/2020/07/30/indonesian-police-seize-200-kilos-of-meth-from-myanmar"},
        {text: "TRAFFIC, Illegal pangolin trade in Myanmar booming, 31 December 2015", 
        link: "https://www.traffic.org/news/illegal-pangolin-trade-in-myanmar-booming/"},
        {text: "UNICEF, Broken promises, shattered dreams, 2004", 
        link: "https://www.unicef.org/media/files/BrokenPromisesFULLREPORT.pdf"},
        {text: "UNODC, Organized crime and instability in Central Africa: A threat assessment, October 2011", 
        link: "https://www.unodc.org/documents/data-and-analysis/Studies/Central_Africa_Report_2011_web.pdf"},
        {text: "UNODC, Patterns and trends of amphetamine-type stimulants and other drugs in East and South-East Asia (and neighbouring regions), 2009",
        link: "http://drugslibrary.wordpress.stir.ac.uk/files/2017/03/2009_Patterns_and_Trends.pdf"},
        {text: "UNODC, Protecting peace and prosperity in Southeast Asia: Synchronizing economic and security agendas, 2016", 
        link: "https://www.unodc.org/documents/southeastasiaandpacific/download/2017/Economic_Integration_07_05_Feb_2016.pdf"},
        {text: "UNODC, The globalization of crime: A transnational organized crime threat assessment, 2010", link: "https://www.unodc.org/unodc/en/data-and-analysis/tocta-2010.html"},
        {text: "UNODC, Transnational organized crime in Eastern Africa: A threat assessment, September 2013", 
        link: "https://www.unodc.org/res/cld/bibliography/transnational-organized-crime-in-eastern-africa-_-a-threat-assessment_html/TOC_East_Africa_2013.pdf"},
        {text: "UNODC, Transnational organized crime in Southeast Asia: Evolution, growth and impact, 2019", 
        link: "https://www.unodc.org/documents/southeastasiaandpacific/Publications/2019/SEA_TOCTA_2019_web.pdf"},
        {text: "UNODC, World drug report 2020", 
        link: "https://wdr.unodc.org/wdr2020/"},
        {text: "Mary Utermohlen, Runway to extinction: Wildlife trafficking in the air transport sector, ROUTES Partnership, 2020", 
        link: "https://www.traffic.org/site/assets/files/12773/runway-to-extinction_web.pdf"},
        {text: "Vietnam Net, Smuggling continues unabated across Vietnamese-Cambodian border, 22 June 2014", 
        link: "http://english.vietnamnet.vn/fms/society/105316/smuggling-continues-unabated-across-vietnamese-cambodian-border.html"},
        {text: "Vietnam News, Inspections up in Lào Cai ore trade, 4 December 2018", 
        link: "https://vietnamnews.vn/politics-laws/talking-law/481324/inspections-up-in-lao-cai-ore-trade.html"},
        {text: "Wildlife Friends Foundation Thailand, Thailand to India wildlife smuggling groups linked to zoological gardens?, 24 March 2019", 
        link: "https://www.wfft.org/wildlife-trade/thailand-to-india-wildlife-smuggling-groups-linked/"},
        {text: "World Customs Organization, 2019 Illicit Trade Report", 
        link: "http://www.wcoomd.org/-/media/wco/public/global/pdf/topics/enforcement-and-compliance/activities-and-programmes/illicit-trade-report/itr_2019_en.pdf?db=web "},
        {text: "WWF, Timber movement and trade in eastern Democratic Republic of Congo and Destination markets in the region, 2012", 
        link: "https://d2ouvy59p0dg6k.cloudfront.net/downloads/regional_timber_movement_and_trade__summary___english.pdf"},
        {text: "Xinhua, Cambodia burns nearly 478 kg of illicit drugs on int'l anti-drug day, 26 June 2020", 
        link: "http://www.xinhuanet.com/english/2020-06/26/c_139168327.htm. %20said"},
        {text: "Xinhua, Vietnam confiscates over 6 tons of illicit drugs in Q1, 4 May 2019", 
        link: "http://www.xinhuanet.com/english/2019-05/04/c_138033788.htmhttp://www.xinhuanet.com/english/2019-05/04/c_138033788.html"},
        {text: "Betul Yuruk, DR Congo’s gold smuggled to UAE, African countries: UN, Anadolu Agency, 13 June 2020", 
        link: "https://www.aa.com.tr/en/africa/dr-congos-gold-smuggled-to-uae-african-countries-un-/1875644"},        
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.view-resources {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: 65px;
  }    
  .view-resources__landing {
    position: relative;
    background-color: #2D233C;
    padding-bottom: 48px !important;

    &:after {
      content: '';
      background: url('~@/assets/line.png');
      background-size: cover;
      opacity: 0.5;
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%; 
      height: 100%;
      @media #{map-get($display-breakpoints, 'xl-only')} {
        bottom: 0;
      }      
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        bottom: -20px;
        left: 0;
        height: 100px;
      }
    }
    
    .view-resources__overlay { 
      pointer-events: none;

      &:after {
        content: '';
        background: linear-gradient(180deg, #E46481 0%, rgba(71, 56, 92, 0) 100%);
        background-blend-mode: overlay;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    
    .container {
      z-index: 2;
      position: relative;
    }
  }

  .view-resources__references {
    background-color: #382e4c;
    position: relative;

    &:before {
      content: '';
      background: linear-gradient(180.26deg, #2D233B 2.24%, #47385C 65.18%);
      background-blend-mode: overlay;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;     
    } 
    
    .view-resources__references-content {
      position: relative;
      ol {
        list-style-type: none;
      }
      a {
        font-weight: 400;
      }      
    }    
  }
   
}
</style>
