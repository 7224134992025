<template>
<div>
  <!-- Nav mobile -->
  <v-app-bar flat fixed height="65" color="#47385C" >
    <v-toolbar-title @click="closeMenu">
      <router-link :to="{ name: 'Landing'}">
        <img src="@/assets/logo/logo-space.png" alt="logo" width="110px" />
      </router-link>      
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon v-if="!expand" color="#E9E2DA" :ripple="false" class="ma-0" @click="toggleMenu"/>
    <v-icon v-if="expand" color="#E9E2DA" class="mr-3" @click="toggleMenu">mdi-close</v-icon>
  </v-app-bar>

  <v-expand-transition>
    <v-card
      id="nav-card"
      v-show="expand"
      width="100%"
      class="mx-auto secondary flex">
        <div 
          v-for="(tab, i) of tabsMobile" :key="i" 
          @click="toggleMenu" 
          class="menuItem" 
          :style="{ backgroundImage: 'url(' + require('@/assets/img/mobile-nav-' + `${i+1}` +'.png') + ')' }">
            <router-link :to="{ name: tab}" style="width:100%; height: 100%;">
              <div class="menuItem-content">
                <p class="heading-3--gi font-weight-bold">{{ tab }}</p>
              </div>
            </router-link>
        </div>          
    </v-card>      
  </v-expand-transition>
  </div>
</template>

<script>

export default {
  name: 'MobileNavbar',
  data() {
    return {
      tabsMobile: ['Story', 'Map', 'About'],
    }
  },
  computed: {
    expand: {
      get: function () {
        return this.$store.getters['mobileNavbarState/navbarOpen'];
      },      
      /* We add a setter */
      set: function (value) {
        this.$store.dispatch('mobileNavbarState/updateNavbar', value);
      }
    },  
  },
  mounted() {
    window.addEventListener('load', () => {
      let vh = window.innerHeight * 0.01;
      document.getElementById('nav-card').style.setProperty('--vh', `${vh}px`);
    }); 
  },
  methods: {
    toggleMenu() {
      this.expand = !this.expand;
    },
    closeMenu() {
      this.expand = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  padding: 8px 16px 6px !important;
}

.flex {
	display: flex;
	flex-direction: column;
  top: 65px;
  position: absolute;
  z-index: 10;
}

a {
  text-decoration: none !important;
}

.menuItem {
  flex: 1 1 0;
  text-align: center;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-size: cover;

  &:before {
    content: '';
    background: linear-gradient(180deg, rgba(48, 39, 62, 0.8) 0%, rgba(71, 56, 92, 0.8) 100%);
    background-blend-mode: overlay;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .menuItem-content {
    position: relative;
    color: #E9E2DA;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}

.v-card {
  height: calc(var(--vh, 1vh) * 100 - 65px);
}
</style>
