<template>
  <v-app id="app-wrapper" :class="{ 'lockScrollMobile': currentRoute === 'Map' || currentRoute === 'Landing' || currentRoute === 'Home' || navbarOpen }">
    <!-- Nav desktop -->
    <Navbar class="hidden-sm-and-down" v-show="currentRoute !== 'Resources'"/>

    <!-- Nav mobile -->
    <MobileNavbar class="hidden-md-and-up"/>

    <!-- Content -->
    <router-view />

    <!-- Footer -->
    <Footer v-if="currentRoute === 'Story' || currentRoute === 'About' || currentRoute === 'Resources'" />
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue';
import MobileNavbar from '@/components/layout/MobileNavbar.vue';
import Footer from '@/components/layout/Footer.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    Navbar,
    MobileNavbar,
    Footer,
  },
  watch: {
    currentRoute() {
      let vh = window.innerHeight * 0.01;
      document.getElementById('app-wrapper').style.setProperty('--vh', `${vh}px`);      
    }
  },  
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    ...mapGetters('mobileNavbarState', ['navbarOpen'])
  },
  data: () => ({
    //
  }),
  mounted() {
    window.addEventListener('load', () => {
      let vh = window.innerHeight * 0.01;
      document.getElementById('app-wrapper').style.setProperty('--vh', `${vh}px`);
    });   
    window.addEventListener("orientationchange", () => {
      // console.log(window.orientation)
      // if (window.orientation === 0) {
      //   window.location.reload(); 
      // }
      let vh = window.innerHeight * 0.01;
      document.getElementById('app-wrapper').style.setProperty('--vh', `${vh}px`);      
    });    
  }  
};
</script>

<style lang="scss">
@import 'styles/_grid.scss';
@import 'styles/_typography.scss';

#app-wrapper {
  height: calc(var(--vh, 1vh) * 100 );
}

.lockScrollMobile {
  @media screen and (max-width: 1100px) {
    overflow: hidden
  }  
}
</style>
