<template>
  <section class="view-content__wrapper">
    <div class="view-content__content">
      <v-container outer py-12>
        <v-row>
          <v-col cols="12">
            <p class="caption--gi text--light">
              A Cambodian harbourside worker at Sihanoukville Port, Phnom Penh, Cambodia. Photo credit: ROB ELLIOTT/AFP via Getty Images
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container inner py-12>
        <v-row>
          <v-col cols="12">
            <p class="referecens--gi font-weight-bold text--gold">SHARE THIS</p>
            <SocialIcons :inputColor="'#B0A894'"/>
          </v-col>
        </v-row>

        <v-row class="py-12 mt-0">
          <v-col cols="12">
            <div class="button-trigger">
            <p class="body-1--gi mb-12">
              China’s Belt and Road Initiative (BRI) might not be unique as far as infrastructure development projects are concerned, but it is hard to find a project of comparable magnitude. The envisaged greater ease of movement of goods and people as well as improved connectivity, which are among the expected benefits of the initiative, alongside the value of investments and construction contracts to date, have been hailed as game changers. And not only for China: countries as diverse as Indonesia and Uzbekistan or Qatar and Kenya have all opted in and are now part of a cohort of 70 countries that are woven into Beijing’s ambitious project.
            </p>

            <img src="@/assets/info/01.png" alt="infographics on possible trafficking scenario" width=100% class="mb-12 py-2" />          

            <p class="body-1--gi mb-8">
              It is clear, however, that it is not only legitimate actors who want a share of the BRI action. Criminal entrepreneurs have wasted no time in seizing opportunities to expand their transnational businesses, whether they operate in the narcotics trade, the smuggling of environmental commodities, human trafficking, money laundering or other illicit markets.
            </p> 

            <p class="body-1--gi mb-12">
              Increased connectivity within and between regions provided by the BRI is improving marketing efficiencies along supply chains between production sites and consumer markets, both existing and prospective ones. At the same time, the sheer volume of maritime and overland trade translates into more opportunities to conceal illicit goods. The bill of landing might declare ‘cashew nuts’, but an inspection might reveal cashew nuts <em>and</em> <a href="https://www.maritime-executive.com/article/malaysian-customs-seize-six-tonnes-of-african-pangolin-scales" target="_blank">pangolin scales</a> concealed in a container arriving from East Africa at its destination port in South East Asia. Examples abound even though only a small proportion of containers are physically checked. As more cargoes transit through African and Asian ports, that percentage is bound to shrink even further.
            </p>

            <img src="@/assets/info/02-1.png" alt="infographics on possible trafficking scenario" width=100% class="mb-12 py-2" />          
            
            <h3 class="heading-3--gi mb-6">The BRI has made the balloon effect easier</h3>

            <p class="body-1--gi mb-8">
              The ‘balloon effect’ is a term used to describe the displacement of criminal activities from one area to another, usually from a place with a strong law enforcement presence to a more permissive environment. It can be argued that the BRI has facilitated this phenomenon in a number of ways. One example is <a href="https://www.theguardian.com/cities/2018/jul/31/no-cambodia-left-chinese-money-changing-sihanoukville" target="_blank">gambling</a> in places such as Myanmar and Cambodia. Physical and online casinos have mushroomed in some jurisdictions along BRI corridors and are often aimed almost exclusively at a Chinese clientele, who are forbidden by law from gambling at home. Another is illicit wildlife trade. The crackdown on this criminal market in China has prompted criminals to intensify their sourcing elsewhere and to make greater use of <a href="https://www.aljazeera.com/economy/2020/3/24/illegal-wildlife-trade-goes-online-as-china-shuts-down-markets" target="_blank">online</a> marketplaces. Moreover, owing to the rapid biodiversity depletion experienced in South East Asia and the growing demand for exotic species, there is a significant increase in transcontinental resource trafficking. In this respect, greater connectivity with Africa – a key source region for illicit wildlife products – is expected to increase these criminal activities. This, coupled with the BRI-driven expansion of ports in <a href="https://www.traffic.org/publications/reports/workshop-proceedings-countering-wildlife-trafficking-through-kenyas-ports/" target="_blank">Kenya</a> and <a href="https://africanelephantjournal.com/mozambican-police-reveal-increased-ivory-trafficking/" target="_blank">Mozambique</a> (key hubs for the illicit trade in ivory, rhino horn and live animals), creates an enabling environment for traffickers eager to supply the large Asian consumer market for illicit wildlife products.
            </p>

            <h3 class="heading-3--gi mb-6">Criminal challenges as an afterthought?</h3>

            <p class="body-1--gi mb-8">
              Other risks might have been on the mind of Chinese officials designing the BRI, but criminal exploitation does not appear to have been taken into great account. In fairness, and despite the degree of opacity for which the BRI is often criticized, China’s State Council has produced guidelines <a href="https://www.usip.org/publications/2020/10/china-getting-serious-about-crime-belt-and-road" target="_blank">defining prohibited and restricted investments</a> with the aim of stopping BRI-related projects from ending up financing activities threatening national security or prohibited practices, such as gambling and pornography.
            </p>

            <p class="body-1--gi mb-8">
              Ironically, however, gambling has boomed in Chinese-built special economic zones strung along the BRI corridors and these areas have become enclaves for all types of illicit trade, money laundering and fraud. A number of media stories involving the likes of Wan Kuok Koi alias ‘Broken Tooth’ and other Chinese <a href="https://asiatimes.com/2020/12/why-the-us-bit-back-at-chinas-broken-tooth/" target="_blank">criminals</a> who are making their fortunes by exploiting the BRI brand are just a reminder of the many ways the initiative can be manipulated for criminal gains.
            </p>

            <hr class="line-divider my-12" />

            <p class="blockquote--gi">
              “IT IS CLEAR THAT NOT ONLY LEGITIMATE ACTORS WANT A SHARE OF THE BRI ACTION.”
            </p>

            <hr class="line-divider my-12" />          

            <h3 class="heading-3--gi mb-6">Changes on the horizon?</h3>

            <p class="body-1--gi mb-8">
              There is no doubt that the <a href="https://www.reuters.com/article/us-health-coronavirus-china-silkroad-idUSKBN23Q0I1" target="_blank">COVID-19</a> pandemic has slowed down BRI projects owing to the contraction of the Chinese economy and disruptions in the global supply chain. This happened at a time when Chinese <a href="https://www.bu.edu/gdp/chinas-overseas-development-finance/" target="_blank">lending</a> to BRI countries had already started to shrink dramatically. Although this might be concerning for the countries relying on Chinese investments, it is hard to imagine that either Beijing or criminals would abandon the ‘project of the century’.
            </p> 
            </div>

          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="upBtn" class="up-button hidden-sm-and-down" @click="scrollToTop">
      <v-icon color="#E9E2DA" style="font-size: 18px;">mdi-arrow-up-bold</v-icon>
      <p class="overline--gi text-uppercase mb-0">Back to top</p>
    </div>    
  </section>
</template>

<script>
import SocialIcons from '@/components/ui/SocialIcons.vue';

export default {
  name: 'SectionContent',
  components: {
    SocialIcons,
  },
  data() {
    return {
      buttonShow: false,
    }
  },
  mounted() {
    const trigger = document.querySelector(".button-trigger");
    const scrollToTopBtn = document.querySelector(".up-button")

    function callback(entries) {
      // The callback will return an array of entries, even if you are only observing a single item
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          scrollToTopBtn.classList.add("showButton");
        } else {
          scrollToTopBtn.classList.remove("showButton");          
        }
      });
    }

    let observer = new IntersectionObserver(callback);
    observer.observe(trigger);

    let heightFooter = document.getElementById("footer").offsetHeight;
    document.getElementById("upBtn").style.bottom = heightFooter + 65 + 'px';
        
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.view-content__wrapper{
  background-color: #362c4a;
  position: relative;

  &:before {
    content: '';
    background: linear-gradient(180deg, #2E243C 0%, #47385C 50.52%, #47385C 99.48%);
    background-blend-mode: overlay;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;     
  }

  .view-content__content {
    position: relative;
  }

  .up-button {
    color: #E9E2DA !important;
    text-align: center;
    background-color: #6D58AD;
    border: 1px solid #E9E2DA;
    padding: 8px;
    position: fixed;
    right: 64px; 
    // bottom: 48px;
    width: 60px;
    border-radius: 3px;
    display: none;
    cursor: pointer;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }
  }

  .showButton {
    display: block;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }    
  }

  a {
    font-weight: 400 !important;
  }
}
</style>