<template>
  <section class="view-about">
    <div class="view-about__landing">
      <v-container outer pt-12 py-md-12>
        <v-row class="py-0 py-md-12">
          <v-col cols="12" md="9" class="py-0 py-md-12">
            <h1 class="heading-1--gi">
              Growing business opportunities for all, criminals included.           
            </h1>
          </v-col>
        </v-row>          
      </v-container>
    </div>
    
    <div class="view-about__aboutGI">
      <v-container outer py-12 class="view-about__aboutGI-content">
        <v-row class="py-0 py-md-12">
          <v-col cols="12" md="4" class="py-6 py-md-12">
            <!-- title hidden in desktop -->            
            <h1 class="heading-4--gi mb-6 hidden-md-and-up">About the Global Initiative</h1>            
            <img src="@/assets/logo/logo-transparent.png" alt="logo" :width="$vuetify.breakpoint.mdAndUp ? '100%' : '60%'" />          
          </v-col>

          <v-col cols="12" md="6" offset-md="2" class="py-0 py-md-12">
            <!-- title hidden in mobile -->
            <h1 class="heading-3--gi mb-3 hidden-sm-and-down">About the Global Initiative</h1>
            <p class="body-3--gi">
              The Global Initiative is an independent civil society organization headquartered in Geneva, Switzerland, with a globally dispersed Secretariat and a high-level advisory board.
            </p>
            <p class="body-3--gi">
              Its network members include prominent law enforcement, governance and development practitioners who are dedicated to seeking new and innovative strategies and responses to organized crime. Learn more <a href="https://globalinitiative.net/about-us/our-story/" target="_blank">here</a>.
            </p>                       
          </v-col>        
        </v-row>             
      </v-container>
    </div>

    <div class="view-about__report">
      <v-container outer py-12 class="view-about__report-content">
        <v-row class="py-0 py-md-12">
          <v-col cols="12" md="6">
            <h1 class="heading-3--gi mb-7 text--dark">China’s New Silk Road: Navigating the organized crime risk</h1>
            <p class="body-3--gi mb-7 text--dark">
              As the first of its kind, the report maps and analyzes the convergence between BRI projects and illicit flows of narcotics, human beings and environmental commodities. Crucially, it highlights how current and future infrastructure across South East Asia, central and East Africa could be exploited for illicit purposes.
            </p>                                     
            <img src="@/assets/img/placeholder-report.png" 
              alt="report to read" 
              width="50%"
              class="hidden-md-and-up report-image-mobile mt-3" />      
            <v-btn outlined class="px-2 py-5" href="https://globalinitiative.net/wp-content/uploads/2021/04/Chinas-New-Silk-Road-Navigating-the-Organized-Crime-Risk-GITOC.pdf" target="_blank"><span class="caption--gi">Read the report</span></v-btn>                         
          </v-col>
          <div class="report-image">
            <img src="@/assets/img/placeholder-report.png" alt="report to read" height="100%" class="hidden-sm-and-down" />                                      
          </div>           
        </v-row>            
      </v-container>
    </div>

    <div class="view-about__ref">
      <v-container outer py-12>
        <v-row class="py-0 py-md-12" justify="space-between">
          <v-col cols="12" md="5">
            <p class="caption--gi text-uppercase">Data Source</p>
            <p class="caption--gi">
              GI-TOC researchers have relied on a variety of sources on the ground as well as on data and analysis by international organizations, law enforcement, national governments, civil society, academia and local and international media outlets. A list of sources can be accessed <router-link :to="{ name: 'Resources'}">
              here</router-link>.
            </p>
            <p class="caption--gi">
              Researchers have strived to collate and triangulate data. However, it shall be noted that official comprehensive information on aspects of the BRI and the projects that formally come under the initiative is lacking. In addition, whilst every effort has been taken to understand and map trafficking routes and associated commodities, the nature of these issues is subject to constant change and, as such, the information may alter accordingly.
            </p>
          </v-col>

          <v-col cols="12" md="4">
            <p class="caption--gi text-uppercase">Credits</p>
            <p class="caption--gi">Research, data collection and review by</p>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <p class="caption--gi mb-0">
                  VIRGINIA COMOLLI<br>
                  NATASHA ROSE<br>
                  SIMONE HAYSOM<br>
                  JASON ELIGH
                </p>         
              </v-col>

              <v-col cols="12" md="6" class="pt-0 pt-md-3">
                <p class="caption--gi">
                  ALASTAIR NELSON<br>
                  TRAVIS SAPERIA<br>
                  DENIS CHABASSIER<br>
                  LINH TRAN PHUONG DANG
                </p>            
              </v-col>
            </v-row>           
          </v-col>

          <v-col cols="6 " md="2">
            <p class="caption--gi text-uppercase">In collaboration with</p>
            <a href="https://www.kontinentalist.com" target="_blank">
              <img src="@/assets/logo/konti-logo.png" alt="logo" width="100%" />
            </a>
          </v-col>                                              
        </v-row>   
           
      </v-container>
      <div class="up-button hidden-sm-and-down" @click="toTop">
        <v-icon color="#E9E2DA" style="font-size: 18px;">mdi-arrow-up-bold</v-icon>
        <p class="overline--gi text-uppercase mb-0">Back to top</p>
      </div>
    </div>    
  </section>
</template>

<script>
export default {
  name: 'About',
  methods: {
    toTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss" scoped>
.view-about {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: 65px;
  }    
  .view-about__landing {
    position: relative;
    background-color: #2D233C;
    padding-bottom: 48px !important;

    &:after {
      content: '';
      background: url('~@/assets/line.png');
      background-size: cover;
      opacity: 0.5;
      position: absolute;
      bottom: -75px;
      left: 0;
      width: 100%; 
      height: 100%;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        bottom: -15px;
        left: 0;
        height: 100px;
      }
    }    
  }

  .view-about__aboutGI {
    background-color: #382e4c;
    position: relative;

    &:before {
      content: '';
      background: linear-gradient(180.26deg, #2D233B 2.24%, #47385C 65.18%);
      background-blend-mode: overlay;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;     
    } 
    
    .view-about__aboutGI-content {
      position: relative;

    }    
  }

  .view-about__report {
    background-size: cover;
    background-position: 50%;
    background: url('~@/assets/img/china-highway.png');
    position: relative;
    border-top: 1px solid #E9E2DA;
    border-bottom: 1px solid #E9E2DA;

    &:before {
      content: '';
      background: linear-gradient(0deg, rgba(233, 226, 218, 0.9), rgba(233, 226, 218, 0.9));
      background-blend-mode: overlay;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;     
    }

    .view-about__report-content {
      position: relative;

      .v-btn {
        border: 1px solid #E9E2DA !important;
        background-color: #6D58AD !important;
        height: auto;
        width: auto;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          margin: 0 auto;
          display: block;
          margin-top: 24px;
        }        
      }
      
      .report-image-mobile {
        display: block;
        margin: 0 auto;
      }
      
      .report-image {
        position: absolute;
        right: 0;
        top: -50px;
        bottom: -50px;
        width: auto;
        overflow: hidden;
        z-index: 10;
      }      
    }
  }

  .view-about__ref {
    background-color: #382e4c;
    position: relative;

    .up-button {
      color: #E9E2DA !important;
      text-align: center;
      background-color: #6D58AD;
      border: 1px solid #E9E2DA;
      padding: 8px;
      position: absolute;
      right: 64px; 
      bottom: 48px;
      width: 60px;
      border-radius: 3px;
      cursor: pointer;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        display: none;
      }
    }
  }     
}
</style>
