<template>
  <div class="wrapper__story">
    <!-- Section Landing -->
    <SectionLanding />
    <!-- Section Content -->
    <SectionContent />
  </div>
</template>

<script>
import SectionLanding from '@/components/views/story/SectionLanding.vue';
import SectionContent from '@/components/views/story/SectionContent.vue';

export default {
  name: 'DigitalStory',
  components: {
    SectionLanding,
    SectionContent,
  },
  data() {
    return {
      refs: [
        {name: 'Kontinentalist', source: 'Kontinentalist', date: '27/1/2021', link: 'www.kontinentalist.com'}
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper__story {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: 65px;
  }   
}
.view__references {
  ol {
    list-style-position: inside;
  }
}
</style>
