const state = {
  // Infrastructure
  belt: [],
  corridor: [],
  project: [],
  allBelt: false,
  allCorridor: false,
  allProject: false, 

  // Hot spots
  allCommodities: true,
  commodities: [
    'Ivory', 'Pangolin or pangolin scales', 'Rhino horn', 'Tiger', 'Timber', 'Minerals', 'Gemstones',
    'Cocaine', 'Heroin', 'Cannabis', 'Synthetic drugs', 
    'Human'
  ],

  // Trafficking Routes
  routeEnv: [],
  routeNarc: [],
  routePeople: false,
  routeGoods: false,
  allRouteEnv: false,
  allRouteNarc: false,

  defaultOptions: {
    center: [93.660434, 40.482324],
    zoom: 2,
  },
};

const mutations = {
  // Belt and Roads
  setBelt(state, selected) {
    state.belt = selected;
    state.allBelt = selected.length === 2 ? true : false;
  },
  setAllBelt(state, selected) {
    state.allBelt= selected;
    state.belt = selected ?  ['belt-1', 'belt-2'] : []
  },

  // Economic corridors
  setCorridor(state, selected) {
    state.corridor = selected;
    state.allCorridor = selected.length === 6 ? true : false;
  },
  setAllCorridor(state, selected) {
    state.allCorridor= selected;
    state.corridor = selected ?  ['corridor-1', 'corridor-2', 'corridor-3', 'corridor-4', 'corridor-5', 'corridor-6', ] : []
  },  

  // Infrastructural projects
  setProject(state, selected) {
    state.project = selected;
    state.allProject = selected.length === 2 ? true : false;
  },
  setAllProject(state, selected) {
    state.allProject= selected;
    state.project = selected ?  ['railways', 'ports'] : []
  },

  // Hot spots
  setAllCommodities(state, selected) {
    state.allCommodities= selected;
    state.commodities = selected ?  [
      'Ivory', 'Pangolin or pangolin scales', 'Rhino horn', 'Tiger', 'Timber', 'Minerals', 'Gemstones',
      'Cocaine', 'Heroin', 'Cannabis', 'Synthetic drugs', 
      'Human'
    ] : []
  },
  
  setCommodities(state, selected) {
    state.commodities = selected;
    state.allCommodities = selected.length === 12 ? true : false;
  },  

  // Trafficking routes  
  setRouteEnv(state, selected) {
    state.routeEnv = selected;
    state.allRouteEnv = selected.length === 7 ? true : false;
  },
  setAllRouteEnv(state, selected) {
    state.allRouteEnv= selected;
    state.routeEnv = selected ?  [
      'Wildlife-flow',
      'Gemstones-flow',
      'Gold-flow',
      'Timber-flow',
      'Charcoal-flow',
      'Waste-flow',
      'ODS-flow',
    ] : []
  },

  setRouteNarc(state, selected) {
    state.routeNarc = selected;
    state.allRouteNarc = selected.length === 4 ? true : false;
  },
  setAllRouteNarc(state, selected) {
    state.allRouteNarc= selected;
    state.routeNarc = selected ?  [
      'Cocaine-flow',
      'Heroin-flow',
      'Precursors-flow',
      'Synthetic-flow',
    ] : []
  },  

  setRoutePeople(state, selected) {
    state.routePeople = selected;
  },  

  setRouteGoods(state, selected) {
    state.routeGoods = selected;
  },  
};

const actions = {
  // Belts
  updateBelt({ commit }, selected) {
    commit('setBelt', selected);
  },
  updateAllBelt({ commit }, selected) {
    commit('setAllBelt', selected);
  },

  // Corridors
  updateCorridor({ commit }, selected) {
    commit('setCorridor', selected);
  },
  updateAllCorridor({ commit }, selected) {
    commit('setAllCorridor', selected);
  },

  // Infrastructural projects
  updateProject({ commit }, selected) {
    commit('setProject', selected);
  },
  updateAllProject({ commit }, selected) {
    commit('setAllProject', selected);
  },

  // Route Env
  updateRouteEnv({ commit }, selected) {
    commit('setRouteEnv', selected);
  },
  updateAllRouteEnv({ commit }, selected) {
    commit('setAllRouteEnv', selected);
  },

  // Route Narc 
  updateRouteNarc({ commit }, selected) {
    commit('setRouteNarc', selected);
  },
  updateAllRouteNarc({ commit }, selected) {
    commit('setAllRouteNarc', selected);
  },

  // Route People
  updateRoutePeople({ commit }, selected) {
    commit('setRoutePeople', selected);
  },

  // Route Goods
  updateRouteGoods({ commit }, selected) {
    commit('setRouteGoods', selected);
  },  

  // Commodities
  updateCommodities({ commit }, selected) {
    commit('setCommodities', selected);
  },
  updateAllCommodities({ commit }, selected) {
    commit('setAllCommodities', selected);
  },

};

const getters = {
  // Infrastructure
  belt: state => state.belt,
  allBelt: state => state.allBelt,

  corridor: state => state.corridor,
  allCorridor: state => state.allCorridor,

  project: state => state.project,
  allProject: state => state.allProject,

  // Hot Spots
  commodities: state => state.commodities,
  allCommodities: state => state.allCommodities,
  
  // Routes
  routeEnv: state => state.routeEnv,
  allRouteEnv: state => state.allRouteEnv,

  routeNarc: state => state.routeNarc,
  allRouteNarc: state => state.allRouteNarc,

  routePeople: state => state.routePeople,

  routeGoods: state => state.routeGoods,

  map: state => state.defaultOptions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
