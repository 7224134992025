<template>
  <!-- Socials -->
  <div class="socials">
    <span v-for="(icon, i) in socials" :key="i" :class="`mr-${iconSpacing}`">
      <a :href= icon.link target="_blank">
        <v-icon :color="inputColor" :style="`font-size: ${size}px`">{{ icon.item }}</v-icon>
      </a>
    </span>
  </div>  
</template>

<script>
export default {
  name: 'Socials',
  props: {
    inputColor: {
      type: String,
      default: '#E9E2DA'
    },
    iconSpacing: {
      type: Number,
      default: 3
    },
    size: {
      type: Number,
      default: 24
    }           
  },
  data() {
    return {
      socials: [
        {item: 'mdi-linkedin', link: 'https://www.linkedin.com/company/the-global-initiative-against-transnational-organized-crime/' },
        {item: 'mdi-facebook', link: 'https://www.facebook.com/gitoc' },
        {item: 'mdi-twitter', link: 'https://twitter.com/GI_TOC' },
        {item: 'mdi-instagram', link: 'https://www.instagram.com/gi_toc/' },
        {item: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCP-lqJdhM-9iDKkswuVcXZA' },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.socials {
  a {
    border: none !important;
    text-decoration: none;
  }

  span:last-child {
    margin-right: 0px !important;
  }
}

</style>
