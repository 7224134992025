<template>
  <section id="fullpage" class="view-landing__hero">
    <div class="video-wrapper">
      <video
        preload="none"
        autoplay
        loop
        muted
        playsinline
        class="video hidden-sm-and-down">
        <source src="@/assets/img/desktop-landing-video.mp4" type="video/mp4">
        Your browser doesn't support HTML5 video.
      </video>
      <video
        preload="none"
        autoplay
        loop
        muted
        playsinline
        class="video hidden-md-and-up">
        <source src="@/assets/img/mobile-landing-video.mp4" type="video/mp4">
        Your browser doesn't support HTML5 video.
      </video>      
    </div>    
    <v-container class="view-landing__hero-content" outer fill-height align-center>
      <v-row>
        <v-col cols="12" md="6" xl="8">
          <h1 class="heading-1--gi mb-8">
            Mapping the Belt and Road Initiative and illicit trade	
          </h1>
          <h2 class="body-3--gi mb-4">
            Hailed as the ‘project of the century’, the Belt and Road Initiative (BRI) could place China even more centre stage in the geopolitical arena. This map, part of The Global Initiative Against Transnational Organized Crime’s expanding Asia-Pacific work to understand, map and analyze instances of organized crime, is an interactive tool to explore one of the least studied aspects of the BRI’s infrastructure: vulnerability to criminal exploitation.
          </h2>
          <h2 class="body-3--gi">            
            The tool currently focuses on South East Asia, central and East Africa.
          </h2>
        </v-col>
      </v-row>
    </v-container>

    <SocialIcons class="social-icons-mobile hidden-md-and-up" :iconSpacing="10" />                            

    <!-- Socials -->
    <div class="view-landing__hero-socials hidden-sm-and-down">
      <h3 class="caption--gi text--light mr-3 pb-2">Connect</h3>
      <SocialIcons class="social-icons"/>
    </div>
  </section>    
</template>

<script>
import SocialIcons from '@/components/ui/SocialIcons.vue';

export default {
  name: 'Landing',
  components: {
    SocialIcons
  },
  data() {
    return {
      socials: [
        {item: 'linkedin', link: '' },
        {item: 'fb', link: '' },
        {item: 'twitter', link: '' },
        {item: 'ig', link: '' },
      ]
    }
  },
  mounted() {
    window.addEventListener('load', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.getElementById('fullpage').style.setProperty('--vh', `${vh}px`);
    });        
  }  
}
</script>

<style lang="scss" scoped>
.view-landing__hero {
  background-size: cover;
  background-position: 50%;
  // background: url('https://picsum.photos/id/870/1920/1080/?grayscale&blur=2');
  z-index: 1;
  height: calc(100vh - 75px);
  position: relative; 
  overflow-y: hidden;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: calc(var(--vh, 1vh) * 100);
  }  

  .video-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    padding:0;
    margin:0;
    left: 0px;
    top: 0px;
    z-index: -1000;
    overflow:hidden;

    .video {
      // min-height: 100%;
      min-width:100%;
      position:absolute;
      bottom:0;
      left:0;
    }
  }  

  &:before {
    content: '';
    background: linear-gradient(180deg, rgba(48, 39, 62, 0.8) 0%, rgba(71, 56, 92, 0.8) 100%);
    background-blend-mode: overlay;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .view-landing__hero-content {
    position: relative;
    color: #E9E2DA; 
  }

  .view-landing__hero-socials {
    position: absolute;
    top: 50%;
    left: calc(100vw - 64px);  
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform:  translateX(-50%) translateY(-50%) rotate(-90deg);    
    width: fit-content;
    
    .social-icons {
      display: inline-block;
    }
    h3 {
      display: inline-block;
    }
  }

  .social-icons-mobile {
    position: absolute;
    bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>